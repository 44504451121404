<template lang="pug">
  .drop-off-fee-table
    table
      thead
        tr
          th.setting-unit.sortable(@click="handleSorting('setting_unit')")
            span {{ $t("company_system.basic_settings.drop_off_fees.setting_unit") }}
            FaIcon.icon(:icon="sortingIcon('setting_unit')")
          th.departure-prefecture.sortable(@click="handleSorting('departure_prefecture_name')")
            span {{ $t("company_system.basic_settings.drop_off_fees.departure_prefecture") }}
            FaIcon.icon(:icon="sortingIcon('departure_prefecture_name')")
          th.departure-store.sortable(@click="handleSorting('departure_shop_name')")
            span {{ $t("company_system.basic_settings.drop_off_fees.departure_shop") }}
            FaIcon.icon(:icon="sortingIcon('departure_shop_name')")
          th.transfer-prefecture.sortable(@click="handleSorting('destination_prefecture_name')")
            span {{ $t("company_system.basic_settings.drop_off_fees.transfer_prefecture") }}
            FaIcon.icon(:icon="sortingIcon('destination_prefecture_name')")
          th.transfer-store.sortable(@click="handleSorting('destination_shop_name')")
            span {{ $t("company_system.basic_settings.drop_off_fees.transfer_shop") }}
            FaIcon.icon(:icon="sortingIcon('destination_shop_name')")
          th.car-type.sortable(@click="handleSorting('car_type_names')")
            span {{ $t("company_system.basic_settings.drop_off_fees.car_type") }}
            FaIcon.icon(:icon="sortingIcon('car_type_names')")
          th.last-updated.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.basic_settings.drop_off_fees.last_updated") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            :key="item.id"
            @click="$emit('select-row', item)"
          )
            td {{ $t(`company_system.basic_settings.drop_off_fees.${item.setting_unit}`) }}
            td {{ item.departure_prefecture_name }}
            td {{ item.departure_shop_name }}
            td {{ item.destination_prefecture_name }}
            td {{ item.destination_shop_name }}
            td {{ item.car_type_names }}
            td {{ item.updated_at }}
            td.remove-action
              AppIconButton.action-button(
                useAppIcon
                icon="trash"
                @click.stop="$emit('remove-row', item)"
              )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },

      sortingData: Object
    },

    mixins: [withSorting]
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .drop-off-fee-table
    +listing-container(110px)
    margin-top: 20px
    margin-left: 10px
    margin-right: 10px

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          max-width: none !important
          margin: 0

          &.option-name
            width: 38%

          &.option-code,
          &.last-updated,
          &.fee-type,
          &.amount-of-money
            width: 14%

          &.actions
            width: 4%
      tbody
        tr
          cursor: pointer

          td
            &.remove-action
              color: $default-purple
              text-align: center

              .action-button
                +icon-button($default-purple)
</style>
